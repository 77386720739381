body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.spinner {
  animation: rotator 1.4s linear infinite;
  height: 50vh;
  width: 50vw;
}

@keyframes rotator {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(270deg);
  }
}
.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}

@keyframes colors {
  0% {
      stroke: #4285F4;
  }
  25% {
      stroke: #DE3E35;
  }
  50% {
      stroke: #F7C223;
  }
  75% {
      stroke: #1B9A59;
  }
  100% {
      stroke: #4285F4;
  }
}
@keyframes dash {
  0% {
      stroke-dashoffset: 187;
  }
  50% {
      stroke-dashoffset: 46.75;
      transform: rotate(135deg);
  }
  100% {
      stroke-dashoffset: 187;
      transform: rotate(450deg);
  }
}

#humDivWrapper{
  position: absolute;
  top:0%;
  left: 0%;
  height: 100vh;
  width: 100vw;
  margin: 0;
  z-index: 2;
}
#humLineWrapper{
  position: absolute;
  top:0%;
  left: 0%;
  height: 100vh;
  width: 100vw;
  margin: 0;
}
.MyHum{
  color: #65b031;
}
.IsoHum{
  color: #65b031;
}

#bgWrapper{
  position: absolute;
  display: inline-block;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.hum-rep-wrapper{
  position: absolute;
  width: fit-content;
  z-index: 2;
}
.hum-rep{
  transform: translate(-50%, -50%);
  line-height: 60%;
  cursor: pointer; 
}

p{margin: 0}